//首页所有一样的详情
<template>
  <div class="details">
    <div class="details_box w-1200 margin-auto">
      <div class="business_tag flexs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/microlecture?type=1' }">微课堂</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/courseDetails' ,query:{id:video_id,type} }">课程目录</el-breadcrumb-item>
          <el-breadcrumb-item>课程详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="details_main b-c-f p-r-50 p-l-50 p-t-30 p-b-40">
        <div class="details_name center m-b-20">{{ details.title }}</div>
        <!-- <div class="details_time center m-b-30">{{ details.createtime }}</div> -->
        <div class="details_txt" v-html="details.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id:null,//
      type:1,
      video_id:null,//
      details:{},//详情
    }
  },
  methods:{
    getDetails () {
      this.$axios.classroomDetail({
        id:this.id
      }).then(res=>{
        this.details = res.data
      })
    }
  },
  created () {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.video_id = this.$route.query.video_id
    this.getDetails()
  }
}
</script>

<style lang="less" scoped>
.details_name {
  color: #333333;
  font-size: 16px;
}
.details_time {
  color: #666666;
  font-size: 14px;
}
</style>